
import mintImage from '../assets/MINTNOTICE.webp';
import '../pagescss/Mint.css'
import solanaLogo from '../assets/solanaLogo.png';
import metaplexlogo from '../assets/No Background - Horizontal White M + Name.png';
import Civillogo from '../assets/Logo2preview22.webp';
import TrumpMAGAlogo from '../assets/Trump_MAGA_logo_2024.svg.png';
import Visitseattlelogo from '../assets/VisitSeattle_white_Meetings.png';
import React, { useRef } from 'react';

const scrollItems = [
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' }
];

const Mint = () => {
    const scrollContainerRef = useRef(null);


    const handleMouseEnter = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.style.animationPlayState = 'paused';
        }
    };

    const handleMouseLeave = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.style.animationPlayState = 'running';
        }
    };
    return (
        <section id="Mint">
            <div className="Mint-container">
                <h4>Mint</h4>
                <div className="Mint-content">
                    <img src={mintImage} alt="MINTNOTICE" className="mint-image" />
                </div>
            </div>
            <div className="divider-line"></div>
            <div
                className="scroll-container"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                ref={scrollContainerRef}
            >
                <div className="scroll-content">
                    {scrollItems.map((item, index) => (
                        <a href={item.link} key={index} target="_blank" rel="noopener noreferrer">
                            <img src={item.src} alt={item.alt} />
                        </a>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Mint;