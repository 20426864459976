
import teamMember1 from '../assets/Team/Friedman.webp';
import teamMember2 from '../assets/Team/Kavan.webp';
import teamMember3 from '../assets/Team/Maxon.webp';
import teamMember4 from '../assets/Team/Pharaoh.webp';
import oldsportGif from '../assets/Team/oldsport.webp';
import '../pagescss/Team.css'
import solanaLogo from '../assets/solanaLogo.png';
import metaplexlogo from '../assets/No Background - Horizontal White M + Name.png';
import Civillogo from '../assets/Logo2preview22.webp';
import TrumpMAGAlogo from '../assets/Trump_MAGA_logo_2024.svg.png';
import Visitseattlelogo from '../assets/VisitSeattle_white_Meetings.png';
import React, { useRef } from 'react';

const scrollItems = [
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' },
    { src: Civillogo, alt: 'Civilianism Club', link: 'https://www.civilianismclub.com' },
    { src: solanaLogo, alt: 'Solana', link: 'https://solana.com' },
    { src: metaplexlogo, alt: 'Metaplex', link: 'https://www.metaplex.com' },
    { src: TrumpMAGAlogo, alt: 'Trump 2024', link: 'https://www.donaldjtrump.com/' },
    { src: Visitseattlelogo, alt: 'Visitseattle', link: 'https://visitseattle.org/' }
];

const Team = () => {
    const scrollContainerRef = useRef(null);


    const handleMouseEnter = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.style.animationPlayState = 'paused';
        }
    };

    const handleMouseLeave = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.style.animationPlayState = 'running';
        }
    };
    return (
        <section id="Team">
            <div className="Team-all-container">
                <div className="team-container">
                    <div className="team-text">
                        <h6>FRIEDMAN</h6>
                        <p>..-. .-. . . -....- ..-. .-. . . -....- .--. .- .-.. . ... - .. -. .  FUCK WAR ! </p>
                        <h6>KAVAN LEO</h6>
                        <p>CHAMPAGNE LOVER, ALCOHOL AND CIGAR COLLECTOR. A 24/7 TRADER. Ex-cb</p>
                        <h6>OLD SPORT</h6>
                        <p>WE'RE FIXIN' TO WELCOME Y'ALL. COME ON IN, OLD SPORT!</p>
                        <h6>MAXON X87</h6>
                        <p>OBSESSED WITH COLONIZING MARS, BUT ELON WON'T INVITE HIM. Ex-Meta.</p>
                        <h6>PHARAOH</h6>
                        <p>NARCOLEPSY, AWAKE WHEN WEB3 WORLD ARRIVES, Ex-Googler.</p>
                    </div>
                    <div className="team-images-grid">
                        <img src={teamMember1} alt="Team Member 1" className="corner-image top-left" />
                        <img src={teamMember2} alt="Team Member 2" className="corner-image top-right" />
                        <img src={teamMember3} alt="Team Member 3" className="corner-image bottom-left" />
                        <img src={teamMember4} alt="Team Member 4" className="corner-image bottom-right" />
                        <div className="center-gif-container">
                            <img src={oldsportGif} alt="Old Sport GIF" className="center-gif" />
                            <div className="overlay">
                                <span className="overlay-text">?</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="divider-line"></div>
            <div
                className="scroll-container"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                ref={scrollContainerRef}
            >
                <div className="scroll-content">
                    {scrollItems.map((item, index) => (
                        <a href={item.link} key={index} target="_blank" rel="noopener noreferrer">
                            <img src={item.src} alt={item.alt} />
                        </a>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Team;