import React, { useEffect, useState } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import xIcon from '../assets/X-logo.svg';
import discordIcon from '../assets/Discord-logo.svg';
import TruthIcon from '../assets/TS-Logomark-4C.svg';
import logoImage from '../assets/The-Seven-Church-Apostles.webp';

const Navbar = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (windowWidth < 768) {
        return null;
    }

    return (
        <>
            <div className="social-icons">
                <a href="https://x.com/CivilianismClub" target="_blank" rel="noopener noreferrer">
                    <img src={xIcon} alt="X" className="icon" />
                </a>
                <a href="https://discord.gg/F68kGkzCZn" target="_blank" rel="noopener noreferrer">
                    <img src={discordIcon} alt="Discord" className="icon" />
                </a>
                <a href="https://truthsocial.com/@CivilianismClub" target="_blank" rel="noopener noreferrer">
                    <img src={TruthIcon} alt="Truth" className="icon" />
                </a>
            </div>
            <div className="navbar-wrapper">
                <img src={logoImage} alt="L7" className="navbar-logo" />
                <nav className="navbar">
                    <div className="navbar-container">
                        <ul className="navbar-menu">
                            <li className="navbar-item">
                                <Link to="/civil">Civil</Link>
                            </li>
                            <li className="navbar-item">
                                <Link to="/mission">Mission</Link>
                            </li>
                            <li className="navbar-item">
                                <Link to="/mint">Mint</Link>
                            </li>
                            <li className="navbar-item">
                                <Link to="/road">Road</Link>
                            </li>
                            <li className="navbar-item">
                                <Link to="/team">Team</Link>
                            </li>
                            <li className="navbar-item">
                                <a href="https://mirror.xyz/0xC05cCe5621AdC94b3932c4155b19C15Af3DcC7Ab" target="_blank" rel="noopener noreferrer">News</a>
                            </li>
                            <li className="navbar-item">
                                <a href="shop.html" target="_blank" rel="noopener noreferrer">Shop</a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </>
    );
};

export default Navbar;