import React, { useState, useEffect } from 'react';
import './BTTB.css';
import upArrowImage from '../assets/uparrow-.png';

const BTTB = () => {
    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => {
        if (window.scrollY > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    return (

        <div className="back-to-top">
            {isVisible &&
                <button className="back-to-top" onClick={scrollToTop}>
                    <img src={upArrowImage} alt="Return Top" />
                </button>
            }
        </div>
    );
};

export default BTTB;